// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/Accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-best-sellers-page-js": () => import("./../../../src/pages/BestSellersPage.js" /* webpackChunkName: "component---src-pages-best-sellers-page-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contest-js": () => import("./../../../src/pages/Contest.js" /* webpackChunkName: "component---src-pages-contest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-pages-order-processing-js": () => import("./../../../src/pages/info-pages/orderProcessing.js" /* webpackChunkName: "component---src-pages-info-pages-order-processing-js" */),
  "component---src-pages-info-pages-return-policy-js": () => import("./../../../src/pages/info-pages/returnPolicy.js" /* webpackChunkName: "component---src-pages-info-pages-return-policy-js" */),
  "component---src-pages-info-pages-shipping-policy-js": () => import("./../../../src/pages/info-pages/shippingPolicy.js" /* webpackChunkName: "component---src-pages-info-pages-shipping-policy-js" */),
  "component---src-pages-men-js": () => import("./../../../src/pages/Men.js" /* webpackChunkName: "component---src-pages-men-js" */),
  "component---src-pages-women-js": () => import("./../../../src/pages/Women.js" /* webpackChunkName: "component---src-pages-women-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

